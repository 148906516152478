import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";
import Image from "next/image";
import GalleryBanner from "components/GalleryBanner";
import Html from "helpers/Html";

export default function Information({data}){
	return (
		<InformationWrapper>
			<CenterBlock>
				<Skull>
					<Image src="/pic/skull.svg" width={234} height={268} alt="baytron welcome"/>
				</Skull>
			</CenterBlock>
			<CenterBlock>
				<GalleryBanner/>
				<div className="info_position">
					<div className="content_box">
						<h1><Html content={data.title}/></h1>
						<div className="sub_title">
							<Image priority={true} src="/pic/tron.svg" width={30} height={35} alt="TRX"/>
							<span>{data.sub_title}</span>
						</div>
						<p>{data.description}</p>
						<h2>{data.collection_title}</h2>
						<Collections>
							<div className="image_block">
								<Image
									priority={true}
									src="/pic/main-apes.png"
									width={300}
									height={300}
									alt="Welcome to the bored ape yacht club"
								/>
								<p>{data.collection_description}</p>
							</div>
							<div className="collection_content">
								{
									data.collection_content.map((item, index) => {
										return (
											<p key={index}>{item}</p>
										)
									})
								}
							</div>
						</Collections>
					</div>
					<Twitter>
						<blockquote className="twitter-tweet"><p lang="en" dir="ltr">🥳🥳<Html content={data.twitter_anons}/> <a href="https://twitter.com/Tronfoundation?ref_src=twsrc%5Etfw">@Tronfoundation</a> <a href="https://twitter.com/BitTorrent?ref_src=twsrc%5Etfw">@BitTorrent</a> <a href="https://t.co/CrQyDCy408">pic.twitter.com/CrQyDCy408</a></p>&mdash; APENFT (@apenftorg) <a href="https://twitter.com/apenftorg/status/1440678479515701249?ref_src=twsrc%5Etfw">{data.twitter_date}</a>
						</blockquote>
					</Twitter>
				</div>
			</CenterBlock>
		</InformationWrapper>
	)
}

const Twitter = styled.div`
	width: 350px;
	position: relative;
	@media only screen and (max-width: 1024px) {
		width: calc(100% + 60px);
		display: flex;
		justify-content: center;
		margin: 40px -30px 60px -30px;
		padding: 40px 30px;
		background: linear-gradient(180deg, #03F4F4 0%, #03A9F4 100%);
	}
`

const Collections = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
	@media only screen and (max-width: 600px) {
		flex-direction: column;
	}
	.image_block{
		max-width: 300px;
		width: 100%;
		@media only screen and (max-width: 600px) {
			max-width: 100%;
		}
		p{
			font-style: italic;
			font-weight: 300;
			font-size: 12px !important;
			line-height: 150%;
			margin: 10px 0 0 0 !important;
		}
	}
	.collection_content{
		width: calc(100% - 300px);
		padding-left: 40px;
		@media only screen and (max-width: 600px) {
			padding-left: 0;
			width: 100%;
			margin-top: 20px;
		}
		p{
			margin-top: 20px !important;
			&:first-child{
				margin-top: 0 !important;
			}
		}
	}
`

const Skull = styled.div`
	position: absolute;
	top: -220px;
	left: 50%;
	transform: translate(-50%, 0);
	@media only screen and (max-width: 768px) {
		top: -140px;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
		left: 0;
		transform: translate(0);
		display: flex;
		justify-content: center;
		top: -170px;
	}
`

const InformationWrapper = styled.section`
	margin-top: 450px;
	position: relative;
	z-index: 100;
	@media only screen and (max-width: 768px) {
		margin-top: 350px;
	}
	@media only screen and (max-width: 600px) {
		margin-top: 300px;
	}
	.gallery_banner{
		margin-bottom: 40px;
		@media only screen and (max-width: 600px) {
			padding-top: 40px;
		}
	}
	.info_position{
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		@media only screen and (max-width: 1024px) {
			flex-direction: column;
		}
	}
	.content_box{
		width: calc(100% - 350px);
		padding-right: 40px;
		@media only screen and (max-width: 1024px) {
			width: 100%;
			padding-right: 0;
		}
		p{
			font-size: 18px;
			line-height: 150%;
			margin: 35px 0 0 0;
		}
		.sub_title{
			font-weight: 500;
			font-size: 25px;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			margin-top: 10px;
			@media only screen and (max-width: 768px) {
				justify-content: center;
				text-align: center;
				font-size: 35px;
			}
			@media only screen and (max-width: 600px) {
				font-size: 20px;
				img{
					display: none !important;
				}
			}
			span{
				margin-left: 10px;
				@media only screen and (max-width: 600px) {
					margin-left: 0;
					margin-top: 10px;
				}
			}
		}
		h2{
			font-weight: 500;
			font-size: 30px;
			line-height: 23px;
			color: #D80027;
			text-transform: uppercase;
			margin-top: 30px;
		}
		h1{
			font-weight: 500;
			font-size: 55px;
			line-height: 52px;
			text-transform: uppercase;
			@media only screen and (max-width: 768px) {
				text-align: center;
				font-size: 40px;
			}
			@media only screen and (max-width: 600px) {
				font-size: 36px;
				line-height: normal;
			}
			span{
				color: #D80027;
			}
		}
	}
`
