import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";
import Image from "next/image";

export default function SubDescription({data}){
	return (
		<SubDescriptionWrapper>
			<CenterBlock>
				<div className="content">
					<h2>{data.title}</h2>
					{
						data.content.map((item, index) => {
							return (
								<p key={index}>{item}</p>
							)
						})
					}
				</div>
				<div className="image_block">
					<video width="100%" height="100%" autoPlay={true} loop={true} muted={true}>
						<source src="/pic/ape.webm"/>
					</video>
				</div>
			</CenterBlock>
		</SubDescriptionWrapper>
	)
}

const SubDescriptionWrapper = styled.section`
	.center_block{
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media only screen and (max-width: 600px) {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}
	}
	.image_block{
		max-width: 350px;
		width: 100%;
		transform: scaleX(-1);
		video{
			border-radius: 30px;
		}
		@media only screen and (max-width: 600px) {
			max-width: 100%;
			margin-top: 20px;
		}
	}
	.content{
		width: calc(100% - 350px);
		padding-right: 120px;
		@media only screen and (max-width: 768px) {
			padding-right: 40px;
		}
		@media only screen and (max-width: 600px) {
			width: 100%;
			padding-right: 0;
		}
		p{
			font-size: 18px;
			line-height: 150%;
		}
		h2{
			font-weight: 500;
			font-size: 40px;
			line-height: 23px;
			color: #D80027;
			text-transform: uppercase;
		}
	}
`
