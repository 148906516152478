import styled from "styled-components";
import Link from "next/link";
import Image from "next/image";

export default function GalleryBanner(){
	return (
		!process.env.STOP_MINTING_BANNER && (
			<>
				<GalleryBannerWrapper className="gallery_banner">
					<div className="bg_link">
						<Link href="/mint"><a>mint an ape</a></Link>
					</div>
					<Image src="/pic/banner.jpg" width={1280} height={112} priority={true} alt="Mint mutant mobile"/>
				</GalleryBannerWrapper>
				<GalleryBannerMobile className="gallery_banner">
					<Link href="/mint"><a>mint</a></Link>
					<Image
						src="/pic/banner-mob.jpg"
						width={600}
						height={268}
						alt="Mint mutant mobile"
					/>
				</GalleryBannerMobile>
			</>
		)
	)
}

const GalleryBannerMobile = styled.section`
	display: none;
	position: relative;
	margin-top: 30px;
	@media only screen and (max-width: 600px) {
		display: block;
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 30;
		font-size: 0;
	}
`

const GalleryBannerWrapper = styled.section`
	width: 100%;
	border-radius: 5px;
	margin-top: 43px;
	position: relative;
	overflow: hidden;
	@media only screen and (max-width: 600px) {
		display: none;
	}
	&:hover{
		.link{
			a{
				background-color: #ffffff;
				color: #111113;
			}
		}
	}
	.bg_link{
		a{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			font-size: 0;
			z-index: 100;
		}
	}
`
