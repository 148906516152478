import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";

export default function FooterTwitter({data}) {
	return (
		<CenterBlock width={700}>
			<FooterTwitterWrapper>
				<a className="twitter-timeline" href="https://twitter.com/BAYCTron?ref_src=twsrc%5Etfw">{data}</a>
			</FooterTwitterWrapper>
		</CenterBlock>
	)
}

const FooterTwitterWrapper = styled.div`
	width: 100%;
	margin-top: 80px;
	height: 700px;
	overflow-x: auto;
	background-color: #ffffff;
	padding: 20px;
	border-radius: 30px;
	position: relative;
	&::-webkit-scrollbar {display: none;}
	@media only screen and (max-width: 600px) {
		border-radius: 20px;
		margin-top: 40px;
	}
`
