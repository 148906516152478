import loadable from '@loadable/component'
import specialArray from 'public/data/main-apes.json'
import Seo from "helpers/Seo";
import Header from "components/Header";
import Information from "layouts/MainPage/Information";
import SubDescription from "layouts/MainPage/SubDescription";
import CenterBlock from "helpers/CenterBlock";
import Head from "next/head";
import FooterTwitter from "layouts/MainPage/FooterTwitter";
const Footer = loadable(() => import('components/Footer'))
const SpecialBox = loadable(() => import('components/SpecialBox'))

export default function Home({data}) {

	return (
		<>
			<Head>
				<script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"/>
			</Head>
			<Seo
				title={data.seo.title}
				description={data.seo.description}
				image="/pic/og.jpg"
			/>
			<Header/>
			<Information data={data.information}/>
			<SubDescription data={data.distribution}/>
			<CenterBlock>
				<SpecialBox title={`${data.specials.special} (3)`} array={specialArray.special}/>
				<SpecialBox title={`${data.specials.tron_fan} (6)`} array={specialArray.tron_fan}/>
				<SpecialBox title={`${data.specials.token_holder} (6)`} array={specialArray.token_holder}/>
			</CenterBlock>
			<FooterTwitter data={data.footer_twitter}/>
			<Footer/>
		</>
	)
}

export async function getStaticProps({locale}) {
	return { props: {data: {...require(`language/main/${locale}.json`)}} }
}
